<template>
  <div class="row flex-center min-vh-100 py-6 text-center">
    <div class="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
      <div class="card">
        <div class="autherrorcontainer">
          <div class="autherror">
            <img src="@/assets/images/raised-hand_270b-1.png"
                loading="lazy" alt="stop hand emoji" class="emoji">
            <p class="page-title">You&#x27;re not authorized to view this page!</p>
            <div class="general-text">
              We&#x27;re sorry! You don&#x27;t have permission to view this page.<br><br>
              If you think this is in error, please contact the team member in charge of
              the vaccination verification program!
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthErrorPage',
};
</script>
